<template>
  <v-container>
    <v-row class="left">
      <v-col class="mb-4">
        <p class="text-h5">
          Dodawanie użytkowników Moodle
        </p>
      </v-col>
    </v-row>
    <v-form ref="form" v-model="formValid">
      <v-row>
        <v-col md="5">
          <v-select
            :items="groups"
            item-text="name"
            item-value="id"
            v-model="selectedGroup"
            :rules="validateGroupSelect()"
            :disabled="newGroup"
            @change="loadGroupMembers"
            label="Wybierz grupę"
          >
          </v-select>
        </v-col>
        <v-col md="2"> </v-col>
        <v-col md="5" class="d-flex">
          <v-checkbox
            @change="newGroupClicked"
            v-model="newGroup"
            label="Utwórz nową"
            class="pa-1 pr-5"
          >
          </v-checkbox>
          <v-text-field
            v-model="group"
            :rules="validateGroupName()"
            label="Nazwa nowej grupy"
            :disabled="!newGroup"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="12">
          <v-data-table
            :headers="headers"
            :items="members"
            item-key="id"
            :loading="loading"
            class="elevation-1"
            hide-default-footer
            disable-pagination
            dense
          >
            <template v-slot:top>
              <v-container>
                <v-row>
                  <v-col md="4" class="d-flex">
                    <v-btn color="primary" @click="addUser">
                      Dodaj
                      <v-icon right>
                        mdi-account
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </template>

            <template v-slot:item.customFirstname="{ item }">
              <v-text-field
                v-model="item.firstname"
                outlined
                class="pt-6"
                dense
                @change="fillUsername(item)"
                :rules="requiredField"
              ></v-text-field>
            </template>
            <template v-slot:item.customLastname="{ item }">
              <v-text-field
                v-model="item.lastname"
                outlined
                class="pt-6"
                dense
                @change="fillUsername(item)"
                :rules="requiredField"
              ></v-text-field>
            </template>
            <template v-slot:item.customEmail="{ item }">
              <v-text-field
                v-model="item.email"
                outlined
                class="pt-6"
                dense
                @change="checkUser(item.id, item.email, null)"
                :rules="validateEmail(item)"
              ></v-text-field>
            </template>
            <template v-slot:item.customUsername="{ item }">
              <span v-if="item.id">
                {{ item.username }}
              </span>
              <span v-else>
                <v-text-field
                  v-model="item.username"
                  outlined
                  class="pt-6"
                  dense
                  @change="checkUser(item.id, null, item.username)"
                  :rules="validateUsername(item)"
                ></v-text-field>
              </span>
            </template>
            <template v-slot:item.customDel="{ item }">
              <v-btn color="error" fab small @click="delUser(item)">
                <v-icon>
                  mdi-delete
                </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <v-row class="left">
        <v-col md="4">
          <v-btn
            color="primary"
            elevation="1"
            class="mr-4"
            :loading="loadingSave"
            @click="saveUsers"
            :disabled="!this.members.length"
          >
            Zapisz użytkowników
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <SnackbarStatus
      :show="snackbar.show"
      :color="snackbar.color"
      :message="snackbar.message"
    />
  </v-container>
</template>

<script>
import MoodleService from "@/api/MoodleService";
import common from "@/mixins/common";
import SnackbarStatus from "@/components/SnackbarStatus";

export default {
  name: "MoodleAdd",
  mixins: [common],
  components: {
    SnackbarStatus
  },
  data: () => ({
    formValid: false,
    newGroup: false,
    group: null,
    loading: false,
    loadingSave: false,
    groups: [],
    selectedGroup: null,
    members: [],
    invalidEmails: [],
    invalidUsernames: [],
    headers: [
      { text: "Imię", value: "customFirstname", sortable: false },
      { text: "Nazwisko", value: "customLastname", sortable: false },
      { text: "E-mail", value: "customEmail", sortable: false },
      { text: "Username", value: "customUsername", sortable: false },
      { text: "Akcja", value: "customDel", sortable: false }
    ],
    requiredField: [v => !!v || "Pole wymagane"]
  }),
  methods: {
    validateFirstname() {
      return [!!this.firstname || "Pole wymagane"];
    },
    validateLastname() {
      return [!!this.lastname || "Pole wymagane"];
    },
    validateGroupName() {
      return [!!this.group || !this.newGroup || "Pole wymagane"];
    },
    validateGroupSelect() {
      return [!!this.selectedGroup || this.newGroup || "Pole wymagane"];
    },
    validateEmail(item) {
      return [
        !!item.email || "Pole wymagane",
        /.+@.+\..+/.test(item.email) || "Niepoprawny e-mail",
        this.invalidEmails.indexOf(item.email) === -1 || "E-mail już istnieje"
      ];
    },
    validateUsername(item) {
      return [
        !!item.username || "Pole wymagane",
        this.invalidUsernames.indexOf(item.username) === -1 ||
          "Uźytkownik już istnieje"
      ];
    },
    checkUser(id, email, username) {
      MoodleService.loadUsersByUsernameAndEmail(email, username)
        .then(res => {
          let arr = res.filter(f => f.id !== id);
          if (arr.length > 0) {
            if (email) {
              this.invalidEmails.push(email);
            }
            if (username) {
              this.invalidUsernames.push(username);
            }
          }
        })
        .catch(error => {
          console.log(error);
          this.showSnackbar("Request to API failed!", "warning");
        });
    },
    saveUsers() {
      this.$refs.form.validate();
      if (this.formValid) {
        this.loadingSave = true;
        MoodleService.saveUsers(this.selectedGroup, this.group, this.members)
          .then(res => {
            console.log(res);
            this.loadingSave = false;
            this.resetForm();
          })
          .catch(error => {
            console.log(error);
            this.loadingSave = false;
            this.showSnackbar("Request to API failed!", "warning");
          });
      }
    },
    fillUsername(item) {
      item.username = (item.firstname.charAt(0) + item.lastname)
        .toLowerCase()
        .replaceAll("ś", "s")
        .replaceAll("ć", "c")
        .replaceAll("ź", "z")
        .replaceAll("ż", "z")
        .replaceAll("ą", "a")
        .replaceAll("ę", "e")
        .replaceAll("ł", "l");
      this.checkUser(item.id, null, item.username);
    },
    addUser() {
      let user = { firstname: "", lastname: "", email: "" };
      this.fillUsername(user);
      this.members.push(user);
    },
    delUser(item) {
      console.log(item);
      this.members = this.members.filter(f => f.username !== item.username);
    },
    loadGroupMembers() {
      this.loading = true;
      if (this.selectedGroup) {
        MoodleService.loadGroupMembers(this.selectedGroup)
          .then(res => {
            console.log(res);
            this.loading = false;
            this.members = res;
            this.$refs.form.validate();
          })
          .catch(error => {
            console.log(error);
            this.loading = false;
            this.showSnackbar("Request to API failed!", "warning");
          });
      }
    },
    newGroupClicked() {
      if (this.newGroup) {
        this.selectedGroup = null;
        this.group = "Grupa 000 - 01.01.2010 (Miejscowość)";
      } else {
        this.group = null;
      }
    },
    loadGroups() {
      MoodleService.loadGroups()
        .then(res => {
          console.log(res);
          this.groups = res;
        })
        .catch(error => {
          console.log(error);
          this.showSnackbar("Request to API failed!", "warning");
        });
    },
    resetForm() {
      this.members = [];
      this.invalidEmails = [];
      this.invalidUsernames = [];
      this.$refs.form.reset();
      this.members = [];
    }
  },
  created() {
    let s = this.$route.params.users;
    console.log(this.$route.params.users);
    if (s) {
      for (let i = 0; i < s.length; i++) {
        let user = {
          firstname: s[i].first_name,
          lastname: s[i].last_name,
          email: s[i].user_email
        };
        this.fillUsername(user);
        this.checkUser(null, user.email, null);
        this.members.push(user);
      }
      this.newGroup = true;
      this.newGroupClicked();
    }
    this.loadGroups();
  }
};
</script>
