var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{staticClass:"left"},[_c('v-col',{staticClass:"mb-4"},[_c('p',{staticClass:"text-h5"},[_vm._v(" Dodawanie użytkowników Moodle ")])])],1),_c('v-form',{ref:"form",model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-row',[_c('v-col',{attrs:{"md":"5"}},[_c('v-select',{attrs:{"items":_vm.groups,"item-text":"name","item-value":"id","rules":_vm.validateGroupSelect(),"disabled":_vm.newGroup,"label":"Wybierz grupę"},on:{"change":_vm.loadGroupMembers},model:{value:(_vm.selectedGroup),callback:function ($$v) {_vm.selectedGroup=$$v},expression:"selectedGroup"}})],1),_c('v-col',{attrs:{"md":"2"}}),_c('v-col',{staticClass:"d-flex",attrs:{"md":"5"}},[_c('v-checkbox',{staticClass:"pa-1 pr-5",attrs:{"label":"Utwórz nową"},on:{"change":_vm.newGroupClicked},model:{value:(_vm.newGroup),callback:function ($$v) {_vm.newGroup=$$v},expression:"newGroup"}}),_c('v-text-field',{attrs:{"rules":_vm.validateGroupName(),"label":"Nazwa nowej grupy","disabled":!_vm.newGroup},model:{value:(_vm.group),callback:function ($$v) {_vm.group=$$v},expression:"group"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.members,"item-key":"id","loading":_vm.loading,"hide-default-footer":"","disable-pagination":"","dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"d-flex",attrs:{"md":"4"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.addUser}},[_vm._v(" Dodaj "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-account ")])],1)],1)],1)],1)]},proxy:true},{key:"item.customFirstname",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticClass:"pt-6",attrs:{"outlined":"","dense":"","rules":_vm.requiredField},on:{"change":function($event){return _vm.fillUsername(item)}},model:{value:(item.firstname),callback:function ($$v) {_vm.$set(item, "firstname", $$v)},expression:"item.firstname"}})]}},{key:"item.customLastname",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticClass:"pt-6",attrs:{"outlined":"","dense":"","rules":_vm.requiredField},on:{"change":function($event){return _vm.fillUsername(item)}},model:{value:(item.lastname),callback:function ($$v) {_vm.$set(item, "lastname", $$v)},expression:"item.lastname"}})]}},{key:"item.customEmail",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticClass:"pt-6",attrs:{"outlined":"","dense":"","rules":_vm.validateEmail(item)},on:{"change":function($event){return _vm.checkUser(item.id, item.email, null)}},model:{value:(item.email),callback:function ($$v) {_vm.$set(item, "email", $$v)},expression:"item.email"}})]}},{key:"item.customUsername",fn:function(ref){
var item = ref.item;
return [(item.id)?_c('span',[_vm._v(" "+_vm._s(item.username)+" ")]):_c('span',[_c('v-text-field',{staticClass:"pt-6",attrs:{"outlined":"","dense":"","rules":_vm.validateUsername(item)},on:{"change":function($event){return _vm.checkUser(item.id, null, item.username)}},model:{value:(item.username),callback:function ($$v) {_vm.$set(item, "username", $$v)},expression:"item.username"}})],1)]}},{key:"item.customDel",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"error","fab":"","small":""},on:{"click":function($event){return _vm.delUser(item)}}},[_c('v-icon',[_vm._v(" mdi-delete ")])],1)]}}])})],1)],1),_c('v-row',{staticClass:"left"},[_c('v-col',{attrs:{"md":"4"}},[_c('v-btn',{staticClass:"mr-4",attrs:{"color":"primary","elevation":"1","loading":_vm.loadingSave,"disabled":!this.members.length},on:{"click":_vm.saveUsers}},[_vm._v(" Zapisz użytkowników ")])],1)],1)],1),_c('SnackbarStatus',{attrs:{"show":_vm.snackbar.show,"color":_vm.snackbar.color,"message":_vm.snackbar.message}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }